let columns = [
  {
    dataField: 'ArrivalDateLocal',
    caption: 'Arrival Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ArrivalTimeSlot',
    caption: 'Arrival Time Slot',
    dataType: 'time',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },

  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Count',
    dataType: 'number',
    visible: true,
    alignment: 'left',
    allowFiltering: true
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'number',
    visible: true,
    alignment: 'left',
    allowFiltering: true
  },
  {
    dataField: 'GrossRetail',
    caption: 'Gross Retail',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true
  },

  {
    dataField: 'NetAdjAmount',
    caption: 'Net Adj Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true
  },
  {
    dataField: 'NetRetail',
    caption: 'Net Retail',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true
  },

  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TaxAmount',
    caption: 'Tax',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true
  },
  {
    dataField: 'TotalSales',
    caption: 'Sales Total',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true
  }
];

let summary = [
  { selector: 'TotalSales', summaryType: 'sum' },
  { selector: 'RowCount', summaryType: 'sum' },
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'TaxAmount', summaryType: 'sum' },
  { selector: 'NetRetail', summaryType: 'sum' },
  { selector: 'GrossRetail', summaryType: 'sum' },
  { selector: 'NetAdjAmount', summaryType: 'sum' }
];

export default { columns: columns, summary: summary };
