let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Date',
    dataType: 'datetime',
    format: 'MM/dd/yyyy',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'AltPayment',
    caption: 'Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RetailPrice',
    caption: 'Retail Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NetAdjAmount',
    caption: 'Net Adj Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'NetRetail',
    caption: 'Net Retail Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TaxAmount',
    caption: 'Tax Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TotalSales',
    caption: 'Total Sales',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CompAmount',
    caption: 'Comp Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CompCount',
    caption: 'Comp Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumTotalCount',
    caption: 'Premium Total count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumAdultCount',
    caption: 'Premium Adult Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumYouthCount',
    caption: 'Premium Youth Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PremiumSeniorCount',
    caption: 'Premium Senior Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NonPremiumAdultCount',
    caption: 'Non Premium Adult Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NonPremiumYouthCount',
    caption: 'Non Premium Youth Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'NonPremiumSeniorCount',
    caption: 'Non Premium Senior Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductTypeName',
    caption: 'Product Type Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'TotalSales', summaryType: 'sum' },
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'TaxAmount', summaryType: 'sum' },
  { selector: 'NetRetail', summaryType: 'sum' },
  { selector: 'NetAdjAmount', summaryType: 'sum' },
  { selector: 'RetailPrice', summaryType: 'sum' },
  { selector: 'CompAmount', summaryType: 'sum' },
  { selector: 'CompCount', summaryType: 'sum' },
  { selector: 'PremiumTotalCount', summaryType: 'sum' },
  { selector: 'PremiumAdultCount', summaryType: 'sum' },
  { selector: 'PremiumYouthCount', summaryType: 'sum' },
  { selector: 'PremiumSeniorCount', summaryType: 'sum' },
  { selector: 'NonPremiumAdultCount', summaryType: 'sum' },
  { selector: 'NonPremiumYouthCount', summaryType: 'sum' },
  { selector: 'NonPremiumSeniorCount', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
