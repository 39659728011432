let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Date',
    dataType: 'datetime',
    format: 'MM/dd/yyyy',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RedemptionDate',
    caption: 'Redemption Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderNumber',
    caption: 'Order Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PromoDescription',
    caption: 'Promo Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ExchangePrice',
    caption: 'Exchange price',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'ExchangePromoAdjustmentAmount',
    caption: 'ExchangePromoAdjustment',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'SalesPrice',
    caption: 'Sales Price',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'Tax',
    caption: 'Tax Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },

  {
    dataField: 'CommissionToSeller',
    caption: 'Commission to Seller',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'TotalSettlementAmount',
    caption: 'Total Settlement Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'Barcode',
    caption: 'Barcode',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'OrderTicketType',
    caption: 'Ticket Type',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'OrderNumber', summaryType: 'count' },
  { selector: 'Tax', summaryType: 'sum' },
  { selector: 'ExchangePrice', summaryType: 'sum' },
  { selector: 'SalesPrice', summaryType: 'sum' },
  { selector: 'CommissionToSeller', summaryType: 'sum' },
  { selector: 'TotalSettlementAmount', summaryType: 'sum' },
  { selector: 'ExchangePromoAdjustmentAmount', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
