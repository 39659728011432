let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Purchase Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PurchaseDate',
    caption: 'Purchase Time',
    dataType: 'datetime',
    format: 'hh:mm:ss',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderNumber',
    caption: 'Order Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'TaxName',
    caption: 'Tax Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Tax',
    caption: 'Amount',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },

  {
    dataField: 'Barcode',
    caption: 'Barcode',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },

  {
    dataField: 'CompPrice',
    caption: 'Comp Price',
    dataType: 'number',
    visible: true,
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'CompCount',
    caption: 'Comp Count',
    dataType: 'number',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
  {
    dataField: 'OrderTicketType',
    caption: 'Ticket Type',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'OrderNumber', summaryType: 'count' },
  { selector: 'Tax', summaryType: 'sum' },
  { selector: 'CompPrice', summaryType: 'sum' },
  { selector: 'CompCount', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
