let columns = [
  {
    dataField: 'PaymentDateLocal',
    caption: 'Payment Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PaymentDate',
    caption: 'Payment Time',
    dataType: 'datetime',
    format: 'hh:mm:ss',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'AgentName',
    caption: 'Agent Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TenderType',
    caption: 'Tender Type ',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Seller',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderNumber',
    caption: 'Order Number',
    dataType: 'number',
    visible: true,
    allowFiltering: true,
  },

  {
    dataField: 'AmountPaid',
    caption: 'Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CustomerName',
    caption: 'Customer Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'PromoDescription',
    caption: 'Promo Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderPriceAdjustmentId',
    caption: 'Order Price Adjustment ID',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  // {
  //   dataField: "ConsignmentId",
  //   caption: "Consignment Id",
  //   dataType: "number",
  //   visible: true,
  //   allowFiltering: true
  // },
  {
    dataField: 'VoucherCode',
    caption: 'Voucher Code',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CreditCardLastFour',
    caption: 'Credit Card Last Four',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ClientName',
    caption: 'Client Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'OrderTicketType',
    caption: 'Ticket Type',
    dataType: 'string',
    visible: true,
    alignment: 'left',
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'OrderNumber', summaryType: 'count' },
  { selector: 'AmountPaid', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
