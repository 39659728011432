export const columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Purchase Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Seller',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TenderType',
    caption: 'Tender Type',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Amount',
    caption: 'Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true,
  },
];
