export const columns = [
  {
    dataField: 'PurchaseDate',
    caption: 'Purchase Date',
    dataType: 'datetime',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'AgentName',
    caption: 'Agent',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
    {
      dataField: 'BillingType',
      caption: 'Billing Type',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'SellerName',
      caption: 'Merchant Name',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
  
    {
      dataField: 'TotalAmount',
      caption: 'Total Amount',
      dataType: 'number',
      format: { type: 'currency',precision: 2},
      visible: true,
      allowFiltering: true
    },
   
    {
      dataField: 'PaymentCount',
      caption: 'Payment Count',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'OrderNumber',
      caption: 'Order Number',
      dataType: 'number',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'CustomerName',
      caption: 'Customer Name',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'Reference',
      caption: 'Reference',
      dataType: 'string',
      visible: true,
      allowFiltering: true
    },
    {
      dataField: 'OrderTicketType',
      caption: 'Ticket Type',
      dataType: 'string',
      visible: true,
      alignment: 'left',
      allowFiltering: true,
    }
  ];
  