export const columns = [
  {
    dataField: 'PurchaseDate',
    caption: 'Payment Date',
    dataType: 'date',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'AgentName',
    caption: 'Agent Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SellerName',
    caption: 'Seller Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'SalesChannel',
    caption: 'Channel Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TenderType',
    caption: 'Tender Type',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TotalAmount',
    caption: 'Amount Paid',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Quantity',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  },
  {
    dataField: 'TransactionCount',
    caption: 'Transaction Quantity',
    dataType: 'string',
    visible: true,
    allowFiltering: true
  }
];
