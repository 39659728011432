let columns = [
  {
    dataField: 'PurchaseDateLocal',
    caption: 'Date',
    dataType: 'datetime',
    format: 'MM/dd/yyyy',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SellerName',
    caption: 'Seller',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProviderName',
    caption: 'Provider',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesChannel',
    caption: 'Sales Channel',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'BundleName',
    caption: 'Bundle Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ProductName',
    caption: 'Product Name',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'AltPayment',
    caption: 'Description',
    dataType: 'string',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TicketCount',
    caption: 'Ticket Count',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'RowCount',
    caption: 'Row Count',
    dataType: 'number',
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ExchangePrice',
    caption: 'Exchange Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'ExchangePromoAdjustmentAmount',
    caption: 'Exchange Promo Adjustment',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'SalesPrice',
    caption: 'Sales Price',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'Tax',
    caption: 'Tax',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'CommissionToSeller',
    caption: 'Commission to Seller',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
  {
    dataField: 'TotalSettlementAmount',
    caption: 'Total Settlement Amount',
    dataType: 'number',
    format: { type: 'currency', precision: 2 },
    alignment: 'left',
    visible: true,
    allowFiltering: true,
  },
];

let summary = [
  { selector: 'SalesPrice', summaryType: 'sum' },
  { selector: 'TicketCount', summaryType: 'sum' },
  { selector: 'RowCount', summaryType: 'sum' },
  { selector: 'Tax', summaryType: 'sum' },
  { selector: 'CommissionToSeller', summaryType: 'sum' },
  { selector: 'ExchangePrice', summaryType: 'sum' },
  { selector: 'ExchangePromoAdjustmentAmount', summaryType: 'sum' },
  { selector: 'TotalSettlementAmount', summaryType: 'sum' },
];

export default { columns: columns, summary: summary };
